import React from 'react';
import './Warehouse.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/ware.png'; // Assuming this is the banner image path

const warehousePhotos = [
  'mag1.png',
  'mag2.png',
  'mag3.png',
  'mag4.png',
  'mag5.png',
];

const Warehouse = () => {
  const { t } = useTranslation();

  return (
    <div className="warehouse-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('warehouse')}</h1>
      </div>
      <div className="warehouse-content">
        <h1>{t('warehouse')}</h1>
        <p>{t('warehouse_intro')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {warehousePhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Warehouse ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warehouse;
