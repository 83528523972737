import React from 'react';
import './White.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/agd.jpg'; // Adjust this path as needed

const whiteGoodsPhotos = [
  'agd1.png',
  'agd2.png',
  'agd3.png',
  'agd4.png',
  'agd5.png',
  'agd6.png',
  'agd7.png',
  'agd8.png',
  'agd9.png',
  'agd10.png',
  'agd11.png',
  'agd12.png',
];

const WhiteGoods = () => {
  const { t } = useTranslation();

  return (
    <div className="white-goods-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('white_goods')}</h1>
      </div>
      <div className="white-goods-content">
        <h1>{t('white_goods')}</h1>
        <p>{t('white_goods_description')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {whiteGoodsPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`White Goods ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteGoods;
