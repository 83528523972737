import React from 'react';
import './Company.css';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import bannerImage from '../../assets/company.webp';

const Company = () => {
  const { t } = useTranslation();

  const images = [
    {
      original: `${process.env.PUBLIC_URL}/company1.png`,
      thumbnail: `${process.env.PUBLIC_URL}/company1.png`,
    },
    {
      original: `${process.env.PUBLIC_URL}/company2.png`,
      thumbnail: `${process.env.PUBLIC_URL}/company2.png`,
    },
    {
      original: `${process.env.PUBLIC_URL}/company3.png`,
      thumbnail: `${process.env.PUBLIC_URL}/company3.png`,
    },
    {
      original: `${process.env.PUBLIC_URL}/company4.png`,
      thumbnail: `${process.env.PUBLIC_URL}/company4.png`,
    },
  ];

  return (
    <div className="company-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('company')}</h1>
      </div>
      <div className="company-content">
        <span id="Text3" dangerouslySetInnerHTML={{ __html: t('company_intro') }}></span>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-container">
          <ImageGallery items={images} autoPlay showThumbnails={false} />
        </div>
      </div>
    </div>
  );
};

export default Company;
