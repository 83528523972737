import React from 'react';
import './Automotive.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/auto.jpg'; // Assuming this is the banner image path

const automotivePhotos = [
  'moto1.png',
  'moto2.png',
  'moto3.png',
  'moto4.png',
  'moto5.png',
];

const Automotive = () => {
  const { t } = useTranslation();

  return (
    <div className="automotive-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('automotive')}</h1>
      </div>
      <div className="automotive-content">
        <p>{t('automotive_description')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {automotivePhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Automotive ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Automotive;
