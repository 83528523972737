import React from 'react';
import './Molding.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/moulding.png'; // Assuming this is the banner image path

const injectionPhotos = [
  'injection1.png',
  'injection2.png',
  'injection3.png',
  'injection4.png',
  'injection5.png',
  'injection6.png',
  'injection7.png',
];

const Moulding = () => {
  const { t } = useTranslation();

  return (
    <div className="injection-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('injection_molding')}</h1>
      </div>
      <div className="injection-content">
        <h1>{t('injection_molding')}</h1>
        <p>{t('injection_intro')}</p>
        <div className="capability-table">
          <h3>{t('injection_capability')}</h3>
          <table>
            <thead>
              <tr>
                <th>Tons</th>
                <th>Count</th>
                <th>Tons</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>50</td><td>2</td><td>300</td><td>2</td></tr>
              <tr><td>80</td><td>1</td><td>380</td><td>3</td></tr>
              <tr><td>90</td><td>3</td><td>400</td><td>1</td></tr>
              <tr><td>100</td><td>1</td><td>420</td><td>3</td></tr>
              <tr><td>150</td><td>7</td><td>500</td><td>1</td></tr>
              <tr><td>160</td><td>1</td><td>650</td><td>2</td></tr>
              <tr><td>200</td><td>1</td><td>720</td><td>1</td></tr>
              <tr><td>210</td><td>3</td><td>800</td><td>3</td></tr>
              <tr><td>230</td><td>3</td><td>860 Twin</td><td>1</td></tr>
              <tr><td>250</td><td>2</td><td>1300</td><td>1</td></tr>
              <tr><td>270</td><td>2</td><td>1300 Twin</td><td>2</td></tr>
            </tbody>
          </table>
        </div>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {injectionPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Injection ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Moulding;
