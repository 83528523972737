import React from 'react';
import './Materials.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/materials.jpg'; // Assuming this is the banner image path

const materials = [
  'ABS',
  'ABS V0/1/2',
  'ABS HT V0',
  'ABS/PC',
  'Elastomer',
  'GPPS',
  'K Resin',
  'PA',
  'PA6',
  'PA6.6',
  'PA GF',
  'PC',
  'PC HT',
  'PMMA',
  'POM',
  'PP',
  'PP V0/1/2',
  'PP with different Fiber',
  'PS',
  'Zytel',
  'and many others'
];

const materialsPhotos = [
  'sur1.png',
  'sur2.png',
  'sur3.png',
  'sur4.png',
  'sur5.png',
];

const Materials = () => {
  const { t } = useTranslation();

  return (
    <div className="materials-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('materials')}</h1>
      </div>
      <div className="materials-content">
        <h1>{t('materials')}</h1>
        <p>{t('materials_intro')}</p>
        <table className="materials-table">
          <thead>
            <tr>
              <th>{t('material')}</th>
            </tr>
          </thead>
          <tbody>
            {materials.map((material, index) => (
              <tr key={index}>
                <td>{material}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {materialsPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Material ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Materials;
