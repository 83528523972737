const resources = {
  en: {
    translation: {
      "home": "Home",
      "about_us": "About Us",
      "production": "Production",
      "technologies": "Technologies",
      "markets": "Markets",
      "quality": "Quality",
      "contact": "Contact",
      "company": "Company",
      "group": "About our group",
      "glance": "EPP at a glance",
      "team": "Our team",
      "events": "Events",
      "assembling": "Assembling",
      "decorating": "Finishing and decorating",
      "molding": "Injection molding",
      "maintenance": "Maintenance",
      "warehouse": "Warehouse",
      "injection": "Injection",
      "insert_molding": "Insert molding",
      "gas_assist_molding": "Gas assist molding",
      "overmolding": "Overmolding",
      "materials": "Materials",
      "glued_table_top": "Glued table top",
      "pad_printing_silk_printing": "Pad printing & silk printing",
      "ultrasonic_welding": "Ultrasonic welding",
      "hot_stamping": "Hot stamping",
      "integrated_operation": "Integrated operation",
      "automotive": "Automotive",
      "textile": "Textile (yarn finishing sector)",
      "medical_dispenser": "Medical Dispenser",
      "white_goods": "White goods",
      "epp_delivers": "EPP delivers plastic components for the white goods industry.",
      "long_standing_experience": "We have long-standing experience.",
      "adapt_to_needs": "We adapt to the specific needs of the client.",
      "custom_plastic_products": "On specific client demands EPP manufactures many other high quality custom plastic products at competitive price.",
      "learn_more": "Learn More About Our Process",
      "our_group": "Our Group",
      "epp_partners": "EPP Partners",
      "contact_intro": "Thank you for your interest in EPP Plastic Injection Molding! Please write us an email, or give us a call, to start a conversation about your needs. We’d Like To Earn Your Trust.",
      "plant_office": "Plant & Office",
      "address": "EPP Sp. z o. o.\nul. Polna 22 Łęg, 55-220 Jelcz Laskowice, POLAND\nVAT No: 899-25-40-872",
      "phone": "Phone",
      "fax": "Fax",
      "email": "Email",
      "secretariat_office": "Secretariat / Office",
      "sales": "Sales",
      "purchasing": "Purchasing",
      "logistic": "Logistic",
      "reach_us": "How to reach EPP",
      "quality_intro": "EPP Quality Improvement Team is a group of multi-skilled people charged with the responsibility of maintaining the high quality level achieved and of improving the production process.",
      "quality_text": "The Quality staff is supported by latest technologies as for example 3D coordinate measuring machine (CMM) and high performance 2D height measurement system. We put all our dedication to develop, refine, and maintain the quality process in order to guarantee high customer satisfaction. Ongoing improvement , investigation of non-conforming product, corrective actions, internal audits and lot traceability are just a part of the process. Quality is a journey that leads to excellence as ultimate aim.",
      "iso_certification": "EPP quality management system has earned certification to the ISO 9001:2015 standard.",
      "iatf_certification": "Since March 2019 we have obtained the IATF 16949 certification. Automotive quality procedures like as APQP, PPAP, FMEA, MSA, SPC are already in use.",
      "gallery_title": "Photo Gallery",
      "company_intro": "EPP is a <strong>full service</strong>, plastic injection manufacturing company located in the south-western <strong>Poland</strong> at Jelcz Laskowice, in the Walbrzyska Special Economical Zone. EPP was founded in <strong>2005</strong> as a branch company of the mother <strong>Italian</strong> company Europlastica Group which boasts more than <strong>40-year experience</strong> in the plastic injection field.",
      "glance_title": "EPP at a Glance",
      "glance_contact": "+(48) 71 381 73 50 sekretariat@epp-pl.com PL / IT",
      "glance_intro": "EPP at a glance",
      "glance_description": "In Poland, in the land area of 38.000 m2, EPP continues to grow and expand its capabilities to meet customer’s needs. The state-of-the-art facilities of about 13.000 m2 are properly designed to nicely run all departments production. Along with thermoplastic aesthetical and technical components injection, EPP provides printing and assembling services.",
      "glance_numbers_title": "Our company in numbers:",
      "glance_employees": "More than 180 employees",
      "glance_hours": "25.000 hours worked each month",
      "glance_machines": "47 injection machines from 50 to 1350 TON",
      "glance_pad_printing": "11 multi-color pad printing machines",
      "glance_silk_printing": "6 silk printing machines",
      "glance_hot_stamping": "3 hot stamping machines",
      "glance_ultrasonic_welding": "6 ultrasonic welding machines",
      "glance_table_top": "A table top assembling line",
      "glance_sub_assembling": "Sub assembling lines",
      "glance_quality_team": "Highly dedicated quality improvement team",
      "pad_silk_printing": "Pad Printing & Silk Printing",
      "team_title": "Our Team",
      "managing_director": "Managing Director",
      "plant_manager": "Plant Manager",
      "logistic_manager": "Logistic Manager",
      "purchasing_manager": "Purchasing Manager",
      "quality_manager": "Quality Manager",
      "chief_accountant": "Chief Accountant",
      "tampo_manager": "Tampo Manager",
      "project_leader": "Project Leader",
      "project_business_development": "Project & Business Development",
      "production_intro": "Proud of its reputation earned through many years of hard work and strong of its Group expertise and know how, EPP provides a full range of custom plastic injection molding services. We offer injection molding, overmolding, insert injection molding as well as micro and gas assist molding, sourcing and molding any grade of material from typical commodity grade plastics, to engineering, functional, and structural grade materials in a variety of colors and formulations. In addition to injection molding, we provide secondary services including sub-assemblies, ultrasonic welding, hot stamping, printing and packaging.",
      "plastic_injection_molding": "Plastic Injection Molding",
      "product_capabilities": "PRODUCT CAPABILITIES:",
      "small_parts": "Small Parts",
      "large_components": "Large Components",
      "multi_component": "Multi-Component",
      "multi_shot_components": "Multi-Shot Components",
      "thin_wall": "Thin Wall",
      "secondary_operations": "SECONDARY OPERATIONS PRODUCT’S FINISHING OR INTEGRATED OPERATION:",
      "hot_welding": "Hot Welding",
      "bonding": "Bonding",
      "pad_printing": "Pad Printing (tampo printing)",
      "silk_screening": "Silk Screening",
      "packaging": "Packaging",
      "molding_process_type": "MOLDING PROCESS TYPE:",
      "twin_injection": "Twin injection",
      "gas_assist": "Gas Assist",
      "gallery": "Photo Gallery",
      "hard_work": "At Epp hard work",
      "and_dedication": "and dedication",
      "along_with_the_choice": "along with the choice of the",
      "right_technology": "right technology",
      "are_the_recipe": "are the recipe for",
      "for_manufacturing": "manufacturing high",
      "plastic_items": "plastic items",
      "from_injection": "From injection molding",
      "to_overmolding": "to overmolding",
      "passing_through": "passing through",
      "padprinting": "padprinting",
      "and_ultrasonic": "and ultrasonic welding",
      "has_earned": "has earned",
      "the_expertise": "the expertise",
      "in_the_use": "in the use",
      "of_a_large": "of a large",
      "variety_of_technologies": "variety of technologies",
      "markets_intro": "For over 10 years we have been manufacturing high-volume plastic parts, at the lowest possible cost, for a wide range of clients. We serve industries operating in different markets including the following:",
      "automotive_detail": "EPP manufactures highly aesthetical components for automotive light systems.",
      "textile_detail": "For the Textile industry EPP manufactures technical components.",
      "white_goods_detail": "For the white goods industry EPP manufactures components for washing machines, dishwashers, dryers, refrigerators and freezers, cooking appliances, boilers, vending machines and other small appliances.",
      "typical_components": "Typical components are:",
      "typical_components_description": "drawers, shelves, handles, containers, baskets, pad printing panels, washing machine and dishwasher bases, washing machine flanges, frames, fans with metal inserts, and many other components in response to our customers' demand.",
      "assembling_intro": "At EPP, we don't just mold plastic, we add value to your products offering assembly services. According to our clients needs, we provide simple manual fitting assembly up to multi step, complex assembly with additional technical testing. Among the integrated operations EPP offers sub-assembly, riveting, gluing, mechanical assembly, engineering, mold making, drilling, trimming and packaging.",
      "finishing_decorating": "Finishing and Decorating",
      "finishing_decorating_intro": "Many secondary services are offered to add value to plastic products: pad printing, silk printing, hot stamping, ultrasonic welding assembly of plastic components, bonding and painting.",
      "pad_printing_intro": "EPP with its 10-color/step pad printing machines offers high quality pad printing services. Pad printing is a simple way to add logos, directions and other identifiers to 2D/3D plastic products. The quality of our pad printing is second to none. For the only Household industry we deliver more than 1.100.000 pad printed plastic front panels per year, among the millions pieces pad printed globally.",
      "injection_molding": "Injection Molding",
      "injection_intro": "Our Injection department has 47 presses various tonnage range from 50 Ton up to 1350 twin Ton (for more information about capability, see the chart below). To maximize efficiency and minimize costs, the manufacturing processes are highly automated and full production schedules (24 hours/7 days per week) are held during most of the year. Precision and quality are not sacrificed and quality control checks for color and physical appearance and product tolerance are constantly performed.",
      "injection_capability": "Plastic Injection Molding capability:",
      "maintenance_intro": "The everyday stool maintenance is provided by our maintenance team. For more complex or urgent maintenance we can easily rely on a specialized mold manufacturer hosted in EPP premises.",
      "warehouse_intro": "Our light and roomy warehouse has computer-controlled equipment and scanner components to control movements and storage of all items in order to guarantee a good warehouse management system (WMS).",
      "insert_molding_intro": "In the insert molding process, inserts (usually made of metal, brass or aluminum) are carefully placed into the molding cavity and the thermoplastic material is molded around them. The result is a tightly bonded plastic piece with assembled, encapsulated inserts.",
      "gas_assist_molding_intro": "During the plastic injection molding process, as soon as the solidification starts, pressurized nitrogen gas is injected into the cavity of the mold spreading thermoplastic into the mold extremities whilst reducing the thicker areas of the piece. One of the advantages of gas assist molding is material saving. In fact, pressurized gas displaces material in the thicker walls creating hollow sections without compromising strength and functional performance of the part. As a result of using less plastic material, the cooling is faster, the cycle time is shorter and molded parts are lighter. In addition to this, sink marks and surface blemishes are dramatically cut, enhancing aesthetic appearance of the part.",
      "overmolding_intro": "Overmolding is the combination of two separate injection molding processes producing one piece improved in appearance and/or durability. In the second step of the overmolding process, heated molten plastic (usually TPE) is molded over a plastic item (previously molded, first step). This technology is often used in cases of complex design.",
      "materials_intro": "Hundreds of commodity and engineering resins are available on the market nowadays. Over the years, our companies have helped clients select the best possible materials for each application. We have developed expertise in the usage of a vast array of raw materials such as:",
      "material": "Material",
      "glued_table_top_intro": "In the Gluing Table Top process an operator along with an anthropomorphic robot spreads a bi-component glue onto a plastic frame enclosing a wooden panel producing a water-resistant, almost impossible to dismount, table top. These assembled components are typically used for dishwashers, washing machines, dryers, and refrigerators (white goods industry).",
      "pad_printing_description": "In the Pad printing (or tampo printing) process, a 2D image is transferred onto a 2D or 3D object. This is accomplished using an indirect offset printing process that involves an image being transferred from the cliché via a silicone pad onto a substrate. Pad printing is used for printing on otherwise difficult to print on products in many industries including appliances, automotive, apparel, electronic objects, etc.",
      "silk_printing_description": "In the silk screen printing the ink is applied to the back of the image carrier and pushed through porous and open areas. The image is on a piece of silk stretched on a frame and backed by a rubber squeegee containing ink. The non printing areas on the silk screen are blocked out, and the ink is pushed through the porous areas corresponding to the design.",
      "ultrasonic_welding_description": "The ultrasonic welding is a process that uses heat (generated by high-frequency mechanical motion) along with applied force to bond components. Vibrations produced by the welding horn (sonotrode) are used to melt the thermoplastic material at the joint line and create a molecular bond between the parts held together under pressure.",
      "hot_stamping_description": "In the hot stamping process pre-dried foils are transferred to a surface at high temperatures. In a hot stamping machine, a die is mounted and heated, with the product to be stamped placed beneath it. A metalized or painted roll-leaf carrier is inserted between the two, and the die presses down through it. The dry foil is impressed onto the surface of the product.",
      "integrated_operation_description": "The integrated operations include services such as sub-assembly, riveting, gluing, mechanical assembly, engineering, mold making, drilling, trimming and packaging.",
      "automotive_description": "EPP successfully implements components for the automotive industry. Especially we produce plastic parts for automotive exterior lighting systems.",
      "textile_description": "EPP increases its reach of activity for the highest quality components. We cooperate within one of the biggest, which is a leader in the yarn finishing machine sector.",
      "medical_description": "EPP produces high-quality components for ECOLAB, worldwide leader in the Medical Dispenser sector.",
      "white_goods_description": "Over the years EPP has built up a reputation as a reliable supplier of plastic components for the White Goods Industry."

    }
  },
  pl: {
    translation: {
      "home": "Strona główna",
      "about_us": "O nas",
      "production": "Produkcja",
      "technologies": "Technologie",
      "markets": "Rynki",
      "quality": "Jakość",
      "contact": "Kontakt",
      "company": "Firma",
      "group": "O naszej grupie",
      "glance": "EPP w skrócie",
      "team": "Nasz zespół",
      "events": "Wydarzenia",
      "assembling": "Montaż",
      "decorating": "Wykończenie i dekoracja",
      "pad_silk_printing": "Drukowanie tampodrukowe i sitodrukowe",
      "molding": "Formowanie wtryskowe",
      "maintenance": "Konserwacja",
      "warehouse": "Magazyn",
      "injection": "Wtrysk",
      "insert_molding": "Formowanie z wkładkami",
      "gas_assist_molding": "Formowanie z gazem",
      "overmolding": "Formowanie z przelewem",
      "materials": "Materiały",
      "glued_table_top": "Klejenie stołu",
      "pad_printing_silk_printing": "Druk tamponowy i sitodruk",
      "ultrasonic_welding": "Spawanie ultradźwiękowe",
      "hot_stamping": "Tłoczenie na gorąco",
      "integrated_operation": "Zintegrowana operacja",
      "automotive": "Automotive",
      "textile": "Tekstylia (sektor wykańczania przędzy)",
      "medical_dispenser": "Dozownik medyczny",
      "white_goods": "AGD",
      "epp_delivers": "EPP dostarcza komponenty z tworzyw sztucznych dla przemysłu AGD.",
      "long_standing_experience": "Mamy wieloletnie doświadczenie.",
      "adapt_to_needs": "Dostosowujemy się do specyficznych potrzeb klienta.",
      "custom_plastic_products": "Na życzenie klienta EPP produkuje wiele innych wysokiej jakości niestandardowych produktów z tworzyw sztucznych w konkurencyjnych cenach.",
      "learn_more": "Dowiedz się więcej o naszym procesie",
      "our_group": "Nasza Grupa",
      "epp_partners": "Partnerzy EPP",
      "contact_intro": "Dziękujemy za zainteresowanie formowaniem wtryskowym tworzyw sztucznych EPP! Prosimy o napisanie do nas e-maila lub telefon, aby rozpocząć rozmowę na temat Państwa potrzeb. Chcielibyśmy zdobyć Państwa zaufanie.",
      "plant_office": "Zakład i Biuro",
      "address": "EPP Sp. z o. o.\nul. Polna 22 Łęg, 55-220 Jelcz Laskowice, POLSKA\nNIP: 899-25-40-872",
      "phone": "Telefon",
      "fax": "Faks",
      "email": "E-mail",
      "secretariat_office": "Sekretariat / Biuro",
      "sales": "Sprzedaż",
      "purchasing": "Zakupy",
      "logistic": "Logistyka",
      "reach_us": "Jak dotrzeć do EPP",
      "quality_intro": "Zespół ds. Doskonalenia Jakości EPP to grupa wielozadaniowych osób odpowiedzialnych za utrzymanie osiągniętego wysokiego poziomu jakości i doskonalenie procesu produkcyjnego.",
      "quality_text": "Personel ds. jakości wspierany jest przez najnowsze technologie, takie jak współrzędnościowa maszyna pomiarowa 3D (CMM) oraz wysokowydajny system pomiaru wysokości 2D. Wkładamy całą naszą dedykację w rozwój, udoskonalanie i utrzymywanie procesu jakości, aby zagwarantować wysoką satysfakcję klienta. Ciągłe doskonalenie, badanie wyrobów niezgodnych, działania korygujące, audyty wewnętrzne i identyfikowalność partii to tylko część procesu. Jakość to podróż, która prowadzi do doskonałości jako ostatecznego celu.",
      "iso_certification": "System zarządzania jakością EPP uzyskał certyfikat zgodności z normą ISO 9001:2015.",
      "iatf_certification": "Od marca 2019 roku posiadamy certyfikat IATF 16949. Procedury jakościowe w branży motoryzacyjnej, takie jak APQP, PPAP, FMEA, MSA, SPC, są już w użyciu.",
      "gallery_title": "Galeria zdjęć",
      "company_intro": "EPP jest <strong>pełnozakresową</strong> firmą zajmującą się produkcją form wtryskowych z tworzyw sztucznych, zlokalizowaną w południowo-zachodniej <strong>Polsce</strong>, w Jelczu Laskowicach, w Wałbrzyskiej Specjalnej Strefie Ekonomicznej. EPP zostało założone w <strong>2005</strong> roku jako oddział włoskiej firmy matki Europlastica Group, która ma ponad <strong>40-letnie doświadczenie</strong> w branży form wtryskowych z tworzyw sztucznych.",
      "glance_title": "EPP w skrócie",
      "glance_contact": "+(48) 71 381 73 50 sekretariat@epp-pl.com PL / IT",
      "glance_intro": "EPP w skrócie",
      "glance_description": "W Polsce, na obszarze 38.000 m2, EPP nadal rozwija się i rozszerza swoje możliwości, aby sprostać potrzebom klientów. Nowoczesne obiekty o powierzchni około 13.000 m2 są odpowiednio zaprojektowane, aby sprawnie zarządzać wszystkimi działami produkcji. Oprócz wtryskiwania estetycznych i technicznych komponentów termoplastycznych, EPP świadczy usługi drukowania i montażu.",
      "glance_numbers_title": "Nasza firma w liczbach:",
      "glance_employees": "Ponad 180 pracowników",
      "glance_hours": "25.000 godzin przepracowanych każdego miesiąca",
      "glance_machines": "47 maszyn wtryskowych od 50 do 1350 TON",
      "glance_pad_printing": "11 wielokolorowych maszyn do tampodruku",
      "glance_silk_printing": "6 maszyn do sitodruku",
      "glance_hot_stamping": "3 maszyny do hot stampingu",
      "glance_ultrasonic_welding": "6 maszyn do zgrzewania ultradźwiękowego",
      "glance_table_top": "Linia montażowa na stole",
      "glance_sub_assembling": "Linie podmontażowe",
      "glance_quality_team": "Wysoce dedykowany zespół ds. poprawy jakości",
      "team_title": "Nasz zespół",
      "managing_director": "Dyrektor Zarządzający",
      "plant_manager": "Kierownik Zakładu",
      "logistic_manager": "Kierownik Logistyki",
      "purchasing_manager": "Kierownik ds. Zakupów",
      "quality_manager": "Kierownik ds. Jakości",
      "chief_accountant": "Główny Księgowy",
      "tampo_manager": "Kierownik ds. Tampo",
      "project_leader": "Lider Projektu",
      "project_business_development": "Projekt & Rozwój Biznesu",
      "production_intro": "Dumni ze swojej reputacji zdobytej dzięki wieloletniej ciężkiej pracy i bogatemu doświadczeniu Grupy, EPP oferuje pełen zakres usług formowania wtryskowego tworzyw sztucznych. Oferujemy formowanie wtryskowe, overmolding, formowanie wkładów oraz formowanie mikro i gazowe, pozyskując i formując wszelkiego rodzaju materiały, od typowych tworzyw sztucznych klasy towarowej, po materiały inżynieryjne, funkcjonalne i strukturalne w różnych kolorach i formulacjach. Oprócz formowania wtryskowego oferujemy usługi wtórne, w tym podzespoły, spawanie ultradźwiękowe, hot stamping, drukowanie i pakowanie.",
      "plastic_injection_molding": "Formowanie wtryskowe tworzyw sztucznych",
      "product_capabilities": "MOŻLIWOŚCI PRODUKCYJNE:",
      "small_parts": "Małe części",
      "large_components": "Duże komponenty",
      "multi_component": "Wieloskładnikowy",
      "multi_shot_components": "Wielokrotne komponenty",
      "thin_wall": "Cienka ściana",
      "secondary_operations": "OPERACJE WTÓRNE WYKOŃCZENIE PRODUKTU LUB OPERACJA ZINTEGROWANA:",
      "hot_welding": "Gorące spawanie",
      "bonding": "Klejenie",
      "pad_printing": "Druk tamponowy",
      "silk_screening": "Sitodruk",
      "packaging": "Pakowanie",
      "molding_process_type": "RODZAJE PROCESÓW FORMOWANIA:",
      "twin_injection": "Podwójne formowanie wtryskowe",
      "gas_assist": "Pomoc gazowa",
      "gallery": "Galeria zdjęć",
      "hard_work": "W Epp ciężka praca",
      "and_dedication": "i zaangażowanie",
      "along_with_the_choice": "wraz z wyborem",
      "right_technology": "odpowiedniej technologii",
      "are_the_recipe": "są receptą na",
      "for_manufacturing": "wytwarzanie wysokiej",
      "plastic_items": "elementów plastikowych",
      "from_injection": "Od formowania wtryskowego",
      "to_overmolding": "po overmolding",
      "passing_through": "przechodząc przez",
      "padprinting": "druk tamponowy",
      "and_ultrasonic": "i zgrzewanie ultradźwiękowe",
      "has_earned": "zdobył",
      "the_expertise": "doświadczenie",
      "in_the_use": "w korzystaniu",
      "of_a_large": "z szerokiej",
      "variety_of_technologies": "gamy technologii",
      "markets_intro": "Od ponad 10 lat produkujemy duże ilości plastikowych części, przy możliwie najniższych kosztach, dla szerokiego grona klientów. Obsługujemy przemysły działające na różnych rynkach, w tym w następujących sektorach:",
      "automotive_detail": "EPP produkuje wysoce estetyczne komponenty do systemów oświetlenia samochodowego.",
      "textile_detail": "Dla przemysłu tekstylnego EPP produkuje komponenty techniczne.",
      "white_goods_detail": "Dla przemysłu AGD EPP produkuje komponenty do pralek, zmywarek, suszarek, lodówek i zamrażarek, urządzeń kuchennych, bojlerów, automatów do sprzedaży i innych małych urządzeń.",
      "typical_components": "Typowe komponenty to:",
      "typical_components_description": "szuflady, półki, uchwyty, pojemniki, kosze, panele do tampondruku, bazy do pralek i zmywarek, kołnierze do pralek, ramy, wentylatory z metalowymi wkładkami i wiele innych komponentów na żądanie naszych klientów.",
      "assembling_intro": "W EPP nie tylko formujemy plastik, ale dodajemy wartość do Twoich produktów, oferując usługi montażu. Zgodnie z potrzebami naszych klientów, zapewniamy prosty montaż ręczny oraz wieloetapowy, skomplikowany montaż z dodatkowymi testami technicznymi. Wśród zintegrowanych operacji EPP oferuje podmontaż, nitowanie, klejenie, montaż mechaniczny, inżynierię, wytwarzanie form, wiercenie, przycinanie i pakowanie.",
      "finishing_decorating": "Wykończenie i dekoracja",
      "finishing_decorating_intro": "Wiele usług dodatkowych oferowanych jest w celu zwiększenia wartości produktów z tworzyw sztucznych: druk tamponowy, druk sitowy, tłoczenie na gorąco, montaż ultradźwiękowy elementów z tworzyw sztucznych, klejenie i malowanie.",
      "pad_printing_intro": "EPP z 10-kolorowymi/etapowymi maszynami do tamponowania oferuje wysokiej jakości usługi drukowania tamponowego. Druk tamponowy to prosty sposób na dodanie logo, instrukcji i innych identyfikatorów do produktów 2D/3D z tworzyw sztucznych. Jakość naszego drukowania tamponowego jest niezrównana. Dla samego przemysłu gospodarstwa domowego dostarczamy rocznie ponad 1.100.000 plastikowych paneli przednich z nadrukiem tamponowym, wśród milionów sztuk tamponowanych na całym świecie.",
      "injection_molding": "Formowanie wtryskowe",
      "injection_intro": "Nasz dział wtrysku posiada 47 pras o różnym tonażu, od 50 ton do 1350 ton (więcej informacji o możliwościach można znaleźć w poniższej tabeli). Aby zmaksymalizować wydajność i zminimalizować koszty, procesy produkcyjne są wysoce zautomatyzowane, a pełne harmonogramy produkcji (24 godziny na dobę, 7 dni w tygodniu) są utrzymywane przez większość roku. Precyzja i jakość nie są poświęcane, a kontrole jakości dotyczące koloru, wyglądu fizycznego i tolerancji produktów są stale przeprowadzane.",
      "injection_capability": "Możliwości formowania wtryskowego:",
      "maintenance_intro": "Codzienna konserwacja stołków jest zapewniana przez nasz zespół konserwacyjny. W przypadku bardziej skomplikowanej lub pilnej konserwacji możemy łatwo polegać na specjalistycznym producencie form, który znajduje się na terenie EPP.",
      "warehouse_intro": "Nasz jasny i przestronny magazyn wyposażony jest w komputerowo sterowane urządzenia i skanery do kontrolowania ruchu i składowania wszystkich przedmiotów, aby zapewnić sprawny system zarządzania magazynem (WMS).",
      "insert_molding_intro": "W procesie formowania wkładek, wkładki (zwykle wykonane z metalu, mosiądzu lub aluminium) są starannie umieszczane w wnęce formy, a materiał termoplastyczny jest formowany wokół nich. Wynikiem jest ciasno związany kawałek plastiku z zamontowanymi, zamkniętymi wkładkami.",
      "gas_assist_molding_intro": "Podczas procesu wtryskiwania tworzyw sztucznych, zaraz po rozpoczęciu solidyfikacji, do wnęki formy wtryskiwany jest sprężony gaz azotowy, który rozprowadza tworzywo termoplastyczne do krańców formy, jednocześnie redukując grubsze obszary elementu. Jedną z zalet formowania wspomaganego gazem jest oszczędność materiału. W rzeczywistości, sprężony gaz wypiera materiał w grubszych ściankach, tworząc puste sekcje bez kompromisów w zakresie wytrzymałości i funkcjonalności części. W wyniku użycia mniejszej ilości materiału z tworzywa sztucznego, proces chłodzenia jest szybszy, czas cyklu jest krótszy, a formowane części są lżejsze. Dodatkowo, znacznie zmniejszają się wady powierzchniowe, poprawiając estetyczny wygląd części.",
      "overmolding_intro": "Nadformowanie to połączenie dwóch oddzielnych procesów wtrysku tworzyw sztucznych, w wyniku których powstaje jeden element poprawiony pod względem wyglądu i/lub trwałości. W drugim etapie procesu nadformowania, ogrzany stopiony plastik (zwykle TPE) jest formowany na plastikowym elemencie (wcześniej formowanym, pierwszy etap). Technologia ta jest często stosowana w przypadku złożonych projektów.",
      "materials_intro": "Obecnie na rynku dostępne są setki tworzyw sztucznych i żywic inżynieryjnych. Na przestrzeni lat nasze firmy pomagały klientom wybierać najlepsze materiały do każdego zastosowania. Rozwinęliśmy doświadczenie w stosowaniu szerokiej gamy surowców, takich jak:",
      "material": "Materiał",
      "glued_table_top_intro": "W procesie klejenia blatu operator wraz z robotem antropomorficznym rozprowadza klej dwuskładnikowy na plastikowej ramie otaczającej drewniany panel, tworząc wodoodporny, niemal niemożliwy do demontażu blat. Te złożone komponenty są zazwyczaj używane do zmywarek, pralek, suszarek i lodówek (branża AGD).",
      "pad_printing_description": "W procesie tampodruku (lub tampodruku) obraz 2D jest przenoszony na obiekt 2D lub 3D. Odbywa się to za pomocą pośredniego procesu druku offsetowego, który polega na przeniesieniu obrazu z kliszy za pomocą silikonowej podkładki na podłoże. Tampodruk jest stosowany do drukowania na trudno drukowalnych produktach w wielu branżach, w tym w AGD, motoryzacji, odzieży, obiektach elektronicznych itp.",
      "silk_printing_description": "W sitodruku atrament jest nakładany na tylną część nośnika obrazu i wprowadzany przez porowate i otwarte obszary. Obraz znajduje się na kawałku jedwabiu rozciągniętym na ramie i wspieranym przez gumową raklę zawierającą atrament. Obszary nie drukujące na jedwabnej siatce są zablokowane, a atrament jest wprowadzany przez porowate obszary odpowiadające projektowi.",
      "ultrasonic_welding_description": "Spawanie ultradźwiękowe to proces wykorzystujący ciepło (generowane przez ruch mechaniczny o wysokiej częstotliwości) oraz siłę do łączenia elementów. Wibracje wytwarzane przez róg spawalniczy (sonotrodę) służą do topienia materiału termoplastycznego na linii złącza i tworzenia wiązania cząsteczkowego między częściami trzymanymi razem pod ciśnieniem.",
      "hot_stamping_description": "W procesie tłoczenia na gorąco suszone folie są przenoszone na powierzchnię w wysokich temperaturach. W maszynie do tłoczenia na gorąco matryca jest zamontowana i podgrzewana, a produkt do tłoczenia umieszczany jest pod nią. Pomiędzy nimi umieszczany jest metalizowany lub malowany nośnik z rolki, a matryca dociska przez niego. Sucha folia jest odciskana na powierzchni produktu.",
      "integrated_operation_description": "Operacje zintegrowane obejmują usługi takie jak montaż podzespołów, nitowanie, klejenie, montaż mechaniczny, inżynieria, wytwarzanie form, wiercenie, przycinanie i pakowanie.",
      "automotive_description": "EPP skutecznie realizuje komponenty dla przemysłu motoryzacyjnego. Szczególnie produkujemy części z tworzyw sztucznych do zewnętrznych systemów oświetlenia samochodowego.",
      "textile_description": "EPP zwiększa swoją działalność o komponenty najwyższej jakości. Współpracujemy z jednym z największych liderów w sektorze maszyn do wykańczania przędzy.",
      "medical_description": "EPP produkuje wysokiej jakości komponenty dla ECOLAB, światowego lidera w sektorze dozowników medycznych.",
      "white_goods_description": "Na przestrzeni lat EPP zyskało reputację niezawodnego dostawcy komponentów z tworzyw sztucznych dla przemysłu AGD."

    }
  },
  it: {
    translation: {
      "home": "Home",
      "about_us": "Chi Siamo",
      "production": "Produzione",
      "technologies": "Tecnologie",
      "markets": "Mercati",
      "quality": "Qualità",
      "contact": "Contatto",
      "company": "Azienda",
      "group": "Il nostro gruppo",
      "pad_silk_printing": "Stampa tampografica e serigrafica",
      "glance": "EPP in sintesi",
      "team": "Il nostro team",
      "events": "Eventi",
      "assembling": "Assemblaggio",
      "decorating": "Finitura e decorazione",
      "molding": "Stampaggio a iniezione",
      "maintenance": "Manutenzione",
      "warehouse": "Magazzino",
      "injection": "Iniezione",
      "insert_molding": "Stampaggio a inserto",
      "gas_assist_molding": "Stampaggio assistito da gas",
      "overmolding": "Sovrastampaggio",
      "materials": "Materiali",
      "glued_table_top": "Tavolo incollato",
      "pad_printing_silk_printing": "Stampa tampografica e serigrafica",
      "ultrasonic_welding": "Saldatura ad ultrasuoni",
      "hot_stamping": "Stampa a caldo",
      "integrated_operation": "Operazione integrata",
      "automotive": "Automobilistico",
      "textile": "Tessile (settore della finitura dei filati)",
      "medical_dispenser": "Dispenser medico",
      "white_goods": "Elettrodomestici",
      "epp_delivers": "EPP fornisce componenti in plastica per l'industria degli elettrodomestici.",
      "long_standing_experience": "Abbiamo una lunga esperienza.",
      "adapt_to_needs": "Ci adattiamo alle esigenze specifiche del cliente.",
      "custom_plastic_products": "Su specifiche richieste del cliente, EPP produce molti altri prodotti in plastica di alta qualità a prezzi competitivi.",
      "learn_more": "Scopri di più sul nostro processo",
      "our_group": "Il nostro gruppo",
      "epp_partners": "Partner EPP",
      "contact_intro": "Grazie per il vostro interesse nella stampaggio a iniezione di plastica EPP! Vi preghiamo di scriverci un'email o di chiamarci per iniziare una conversazione sulle vostre esigenze. Vorremmo guadagnarci la vostra fiducia.",
      "plant_office": "Impianto e Ufficio",
      "address": "EPP Sp. z o. o.\nul. Polna 22 Łęg, 55-220 Jelcz Laskowice, POLONIA\nPartita IVA: 899-25-40-872",
      "phone": "Telefono",
      "fax": "Fax",
      "email": "E-mail",
      "secretariat_office": "Segreteria / Ufficio",
      "sales": "Vendite",
      "purchasing": "Acquisti",
      "logistic": "Logistica",
      "reach_us": "Come raggiungere EPP",
      "quality_intro": "Il Team di Miglioramento della Qualità di EPP è un gruppo di persone con molteplici competenze incaricate di mantenere il livello di qualità raggiunto e di migliorare il processo produttivo.",
      "quality_text": "Il personale addetto alla qualità è supportato dalle ultime tecnologie, come la macchina di misura a coordinate 3D (CMM) e il sistema di misurazione dell'altezza 2D ad alte prestazioni. Mettiamo tutta la nostra dedizione nello sviluppo, nel miglioramento e nel mantenimento del processo di qualità per garantire un'elevata soddisfazione del cliente. Il miglioramento continuo, l'indagine sui prodotti non conformi, le azioni correttive, gli audit interni e la tracciabilità dei lotti sono solo una parte del processo. La qualità è un viaggio che porta all'eccellenza come obiettivo finale.",
      "iso_certification": "Il sistema di gestione della qualità di EPP ha ottenuto la certificazione secondo lo standard ISO 9001:2015.",
      "iatf_certification": "Dal marzo 2019 abbiamo ottenuto la certificazione IATF 16949. Le procedure di qualità automobilistiche come APQP, PPAP, FMEA, MSA, SPC sono già in uso.",
      "gallery_title": "Galleria fotografica",
      "company_intro": "EPP è un'azienda di produzione di stampi a iniezione di plastica a <strong>servizio completo</strong>, situata nel sud-ovest della <strong>Polonia</strong>, a Jelcz Laskowice, nella Zona Economica Speciale di Walbrzych. EPP è stata fondata nel <strong>2005</strong> come filiale dell'azienda madre italiana Europlastica Group, che vanta oltre <strong>40 anni di esperienza</strong> nel campo dello stampaggio a iniezione di plastica.",
      "glance_title": "EPP in sintesi",
      "glance_contact": "+(48) 71 381 73 50 sekretariat@epp-pl.com PL / IT",
      "glance_intro": "EPP in sintesi",
      "glance_description": "In Polonia, su un'area di 38.000 m2, EPP continua a crescere e ampliare le proprie capacità per soddisfare le esigenze dei clienti. Le strutture all'avanguardia di circa 13.000 m2 sono adeguatamente progettate per gestire bene tutti i reparti di produzione. Oltre all'iniezione di componenti estetici e tecnici termoplastici, EPP offre servizi di stampa e assemblaggio.",
      "glance_numbers_title": "La nostra azienda in numeri:",
      "glance_employees": "Più di 180 dipendenti",
      "glance_hours": "25.000 ore lavorate ogni mese",
      "glance_machines": "47 macchine per stampaggio a iniezione da 50 a 1350 TON",
      "glance_pad_printing": "11 macchine per tampografia multicolore",
      "glance_silk_printing": "6 macchine per serigrafia",
      "glance_hot_stamping": "3 macchine per stampa a caldo",
      "glance_ultrasonic_welding": "6 macchine per saldatura ad ultrasuoni",
      "glance_table_top": "Una linea di assemblaggio da tavolo",
      "glance_sub_assembling": "Linee di sottoassemblaggio",
      "glance_quality_team": "Team di miglioramento della qualità altamente dedicato",
      "team_title": "Il nostro team",
      "managing_director": "Direttore Generale",
      "plant_manager": "Direttore di Stabilimento",
      "logistic_manager": "Responsabile Logistica",
      "purchasing_manager": "Responsabile Acquisti",
      "quality_manager": "Responsabile Qualità",
      "chief_accountant": "Capo Contabile",
      "tampo_manager": "Responsabile Tampo",
      "project_leader": "Leader del Progetto",
      "project_business_development": "Progetto e Sviluppo Aziendale",
      "production_intro": "Orgogliosi della sua reputazione guadagnata attraverso molti anni di duro lavoro e forti dell'esperienza e del know-how del suo Gruppo, EPP offre una gamma completa di servizi personalizzati di stampaggio a iniezione di plastica. Offriamo stampaggio a iniezione, sovrastampaggio, stampaggio a inserto, nonché stampaggio micro e assistito da gas, approvvigionamento e stampaggio di qualsiasi tipo di materiale, dai tipici materiali plastici di qualità commerciale, ai materiali ingegneristici, funzionali e strutturali in una varietà di colori e formulazioni. Oltre allo stampaggio a iniezione, forniamo servizi secondari tra cui sottoassiemi, saldatura ad ultrasuoni, hot stamping, stampa e imballaggio.",
      "plastic_injection_molding": "Stampaggio a iniezione di plastica",
      "product_capabilities": "CAPACITÀ DEL PRODOTTO:",
      "small_parts": "Piccole parti",
      "large_components": "Grandi componenti",
      "multi_component": "Multi-componente",
      "multi_shot_components": "Componenti multi-colpo",
      "thin_wall": "Parete sottile",
      "secondary_operations": "OPERAZIONI SECONDARIE FINITURA PRODOTTO O OPERAZIONE INTEGRATA:",
      "hot_welding": "Saldatura a caldo",
      "bonding": "Incollaggio",
      "pad_printing": "Stampa tampografica",
      "silk_screening": "Serigrafia",
      "packaging": "Imballaggio",
      "molding_process_type": "TIPO DI PROCESSO DI STAMPAGGIO:",
      "twin_injection": "Doppia iniezione",
      "gas_assist": "Assistenza gas",
      "gallery": "Galleria fotografica",
      "hard_work": "Da Epp duro lavoro",
      "and_dedication": "e dedizione",
      "along_with_the_choice": "insieme alla scelta della",
      "right_technology": "tecnologia giusta",
      "are_the_recipe": "sono la ricetta per",
      "for_manufacturing": "la produzione di",
      "plastic_items": "articoli in plastica",
      "from_injection": "Dallo stampaggio a iniezione",
      "to_overmolding": "all'overmolding",
      "passing_through": "passando per",
      "padprinting": "la tampografia",
      "and_ultrasonic": "e la saldatura ad ultrasuoni",
      "has_earned": "ha acquisito",
      "the_expertise": "l'esperienza",
      "in_the_use": "nell'uso",
      "of_a_large": "di una vasta",
      "variety_of_technologies": "gamma di tecnologie",
      "markets_intro": "Da oltre 10 anni produciamo parti in plastica ad alto volume, al costo più basso possibile, per una vasta gamma di clienti. Serviamo industrie operanti in diversi mercati, tra cui i seguenti:",
      "automotive_detail": "EPP produce componenti altamente estetici per i sistemi di illuminazione automobilistica.",
      "textile_detail": "Per l'industria tessile, EPP produce componenti tecnici.",
      "white_goods_detail": "Per l'industria degli elettrodomestici, EPP produce componenti per lavatrici, lavastoviglie, asciugatrici, frigoriferi e congelatori, elettrodomestici da cucina, caldaie, distributori automatici e altri piccoli elettrodomestici.",
      "typical_components": "I componenti tipici sono:",
      "typical_components_description": "cassetti, ripiani, maniglie, contenitori, cestini, pannelli per tampografia, basi per lavatrici e lavastoviglie, flange per lavatrici, telai, ventole con inserti metallici e molti altri componenti in risposta alle richieste dei nostri clienti.",
      "assembling_intro": "In EPP non ci limitiamo a stampare plastica, aggiungiamo valore ai vostri prodotti offrendo servizi di assemblaggio. Secondo le esigenze dei nostri clienti, forniamo assemblaggi semplici manuali fino ad assemblaggi complessi a più fasi con ulteriori test tecnici. Tra le operazioni integrate, EPP offre sottoassiemi, rivettatura, incollaggio, assemblaggio meccanico, ingegneria, produzione di stampi, foratura, rifilatura e imballaggio.",
      "finishing_decorating": "Finitura e Decorazione",
      "finishing_decorating_intro": "Molti servizi secondari sono offerti per aggiungere valore ai prodotti in plastica: stampa tampografica, stampa serigrafica, stampa a caldo, assemblaggio a ultrasuoni di componenti in plastica, incollaggio e verniciatura.",
      "pad_printing_intro": "EPP con le sue macchine tampografiche a 10 colori/passaggi offre servizi di stampa tampografica di alta qualità. La stampa tampografica è un modo semplice per aggiungere loghi, istruzioni e altri identificatori ai prodotti in plastica 2D/3D. La qualità della nostra stampa tampografica è insuperabile. Solo per l'industria degli elettrodomestici forniamo oltre 1.100.000 pannelli frontali in plastica stampati a tampone all'anno, tra i milioni di pezzi stampati a tampone a livello globale.",
      "injection_molding": "Stampaggio a Iniezione",
      "injection_intro": "Il nostro reparto di iniezione dispone di 47 presse con vari tonnellaggi, da 50 tonnellate fino a 1350 tonnellate gemelle (per ulteriori informazioni sulle capacità, vedere la tabella sottostante). Per massimizzare l'efficienza e ridurre al minimo i costi, i processi di produzione sono altamente automatizzati e gli orari di produzione completi (24 ore su 24, 7 giorni su 7) vengono mantenuti per la maggior parte dell'anno. La precisione e la qualità non vengono sacrificate e i controlli di qualità per il colore, l'aspetto fisico e la tolleranza del prodotto vengono costantemente effettuati.",
      "injection_capability": "Capacità di stampaggio a iniezione:",
      "maintenance_intro": "La manutenzione quotidiana degli sgabelli è fornita dal nostro team di manutenzione. Per manutenzioni più complesse o urgenti possiamo facilmente fare affidamento su un produttore di stampi specializzato ospitato nei locali EPP.",
      "warehouse_intro": "Il nostro magazzino luminoso e spazioso è dotato di attrezzature controllate da computer e scanner per controllare i movimenti e lo stoccaggio di tutti gli articoli per garantire un buon sistema di gestione del magazzino (WMS).",
      "insert_molding_intro": "Nel processo di stampaggio a inserto, gli inserti (di solito in metallo, ottone o alluminio) vengono accuratamente posizionati nella cavità dello stampo e il materiale termoplastico viene stampato intorno ad essi. Il risultato è un pezzo di plastica strettamente legato con inserti assemblati e incapsulati.",
      "gas_assist_molding_intro": "Durante il processo di stampaggio a iniezione di plastica, non appena inizia la solidificazione, il gas azoto pressurizzato viene iniettato nella cavità dello stampo distribuendo il termoplastico nelle estremità dello stampo riducendo al contempo le aree più spesse del pezzo. Uno dei vantaggi dello stampaggio assistito da gas è il risparmio di materiale. Infatti, il gas pressurizzato sposta il materiale nelle pareti più spesse creando sezioni cave senza compromettere la resistenza e le prestazioni funzionali del pezzo. Utilizzando meno materiale plastico, il raffreddamento è più rapido, il tempo del ciclo è più breve e le parti stampate sono più leggere. Inoltre, i segni di affondamento e i difetti superficiali vengono drasticamente ridotti, migliorando l'aspetto estetico del pezzo.",
      "overmolding_intro": "Lo stampaggio a doppio materiale è la combinazione di due distinti processi di stampaggio a iniezione che producono un pezzo migliorato nell'aspetto e/o nella durata. Nel secondo passaggio del processo di stampaggio a doppio materiale, la plastica fusa riscaldata (di solito TPE) viene stampata su un oggetto di plastica (precedentemente stampato, primo passaggio). Questa tecnologia è spesso utilizzata in caso di design complessi.",
      "materials_intro": "Attualmente sul mercato sono disponibili centinaia di resine di consumo e ingegneristiche. Nel corso degli anni, le nostre aziende hanno aiutato i clienti a selezionare i migliori materiali possibili per ogni applicazione. Abbiamo sviluppato competenze nell'uso di una vasta gamma di materie prime come:",
      "material": "Materiale",
      "glued_table_top_intro": "Nel processo di incollaggio del piano un operatore insieme a un robot antropomorfo distribuisce una colla bicomponente su un telaio in plastica che racchiude un pannello di legno, producendo un piano resistente all'acqua, quasi impossibile da smontare. Questi componenti assemblati sono tipicamente utilizzati per lavastoviglie, lavatrici, asciugatrici e frigoriferi (settore degli elettrodomestici).",
      "pad_printing_description": "Nel processo di stampa tampografica (o tampografica), un'immagine 2D viene trasferita su un oggetto 2D o 3D. Ciò avviene utilizzando un processo di stampa offset indiretto che comporta il trasferimento di un'immagine dal cliché tramite un tampone in silicone su un substrato. La stampa tampografica viene utilizzata per la stampa su prodotti altrimenti difficili da stampare in molte industrie tra cui elettrodomestici, automobilistico, abbigliamento, oggetti elettronici, ecc.",
      "silk_printing_description": "Nella serigrafia l'inchiostro viene applicato sul retro del portaimmagine e spinto attraverso aree porose e aperte. L'immagine è su un pezzo di seta teso su una cornice e supportato da una spatola in gomma contenente inchiostro. Le aree non stampanti sullo schermo di seta sono bloccate e l'inchiostro viene spinto attraverso le aree porose corrispondenti al design.",
      "ultrasonic_welding_description": "La saldatura ad ultrasuoni è un processo che utilizza il calore (generato dal movimento meccanico ad alta frequenza) insieme alla forza applicata per unire i componenti. Le vibrazioni prodotte dal corno di saldatura (sonotrodo) vengono utilizzate per fondere il materiale termoplastico sulla linea di giunzione e creare un legame molecolare tra le parti tenute insieme sotto pressione.",
      "hot_stamping_description": "Nel processo di stampa a caldo, le lamine pre-essiccate vengono trasferite su una superficie ad alte temperature. In una macchina per la stampa a caldo, una matrice viene montata e riscaldata, con il prodotto da stampare posizionato sotto di essa. Un supporto in lamina metallica o dipinta viene inserito tra i due e la matrice preme verso il basso attraverso di esso. La lamina asciutta viene impressa sulla superficie del prodotto.",
      "integrated_operation_description": "Le operazioni integrate includono servizi come pre-assemblaggio, rivettatura, incollaggio, assemblaggio meccanico, ingegneria, produzione di stampi, foratura, rifilatura e imballaggio.",
      "automotive_description": "EPP implementa con successo componenti per l'industria automobilistica. In particolare, produciamo parti in plastica per sistemi di illuminazione esterni automobilistici.",
      "textile_description": "EPP amplia il suo campo di attività per i componenti di più alta qualità. Cooperiamo con uno dei maggiori leader nel settore delle macchine per la finitura del filo.",
      "medical_description": "EPP produce componenti di alta qualità per ECOLAB, leader mondiale nel settore dei dispenser medici.",
      "white_goods_description": "Negli anni, EPP si è costruita una reputazione come fornitore affidabile di componenti in plastica per l'industria degli elettrodomestici."

    }
  }
};

export default resources;
