import React from 'react';
import './Maintenance.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/main.png'; // Assuming this is the banner image path

const maintenancePhotos = [
  'narz1.png',
  'narz2.png',
  'narz3.png',
  'narz4.png',
  'narz5.png',
  'narz6.png',
  'narz7.png',
];

const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <div className="maintenance-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('maintenance')}</h1>
      </div>
      <div className="maintenance-content">
        <h1>{t('maintenance')}</h1>
        <p>{t('maintenance_intro')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {maintenancePhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Maintenance ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
