import React from 'react';
import './Quality.css';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import bannerImage from '../../assets/quality.webp';

const Quality = () => {
  const { t } = useTranslation();

  const images = [
    {
      original: `${process.env.PUBLIC_URL}/jak1.png`,
      thumbnail: `${process.env.PUBLIC_URL}/jak1.png`,
    },
    {
      original: `${process.env.PUBLIC_URL}/jak2.png`,
      thumbnail: `${process.env.PUBLIC_URL}/jak2.png`,
    },
    {
      original: `${process.env.PUBLIC_URL}/jak3.png`,
      thumbnail: `${process.env.PUBLIC_URL}/jak3.png`,
    },
    {
      original: `${process.env.PUBLIC_URL}/jak4.png`,
      thumbnail: `${process.env.PUBLIC_URL}/jak4.png`,
    },
    {
      original: `${process.env.PUBLIC_URL}/jak5.png`,
      thumbnail: `${process.env.PUBLIC_URL}/jak5.png`,
    },
    {
      original: `${process.env.PUBLIC_URL}/jak6.png`,
      thumbnail: `${process.env.PUBLIC_URL}/jak6.png`,
    },
  ];

  return (
    <div className="quality-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('quality')}</h1>
      </div>
      <div className="quality-content">
        <h2>{t('quality')}</h2>
        <p>{t('quality_intro')}</p>
        <p>{t('quality_text')}</p>
        <p><strong>{t('iso_certification')}</strong></p>
        <p><strong>{t('iatf_certification')}</strong></p>
        <div className="certifications">
          <img src={`${process.env.PUBLIC_URL}/certyfikat1.png`} alt="Certification 1" />
          <img src={`${process.env.PUBLIC_URL}/certyfikat2.png`} alt="Certification 2" />
          <img src={`${process.env.PUBLIC_URL}/certyfikat3.png`} alt="Certification 3" />
        </div>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-container">
          <ImageGallery items={images} autoPlay showThumbnails={false} />
        </div>
      </div>
    </div>
  );
};

export default Quality;
