import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutUs.css';

const AboutUs = () => {
  const { t } = useTranslation();
  const backgroundImage = `${process.env.PUBLIC_URL}/test.png`;

  return (
    <div className="about-us">
      <div className="jumbotron">
        <video autoPlay muted loop className="jumbotron-video">
          <source src={`${process.env.PUBLIC_URL}/epp.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="jumbotron-content">
          <img src={`${process.env.PUBLIC_URL}/logo.webp`} alt="Logo" className="jumbotron-logo" />
          <h1 className="jumbotron-title">EPP Your Plastic Injection Molder</h1>
        </div>
      </div>
      <div className="next-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <h2 className="section-title">{t('epp_delivers')}</h2>
        <div className="section-cards">
          <div className="card">
            <img src={`${process.env.PUBLIC_URL}/icon1.svg`} alt="Icon 1" className="card-icon" />
            <p className="card-text">{t('long_standing_experience')}</p>
          </div>
          <div className="card">
            <img src={`${process.env.PUBLIC_URL}/icon2.svg`} alt="Icon 2" className="card-icon" />
            <p className="card-text">{t('adapt_to_needs')}</p>
          </div>
          <div className="card">
            <img src={`${process.env.PUBLIC_URL}/icon3.svg`} alt="Icon 3" className="card-icon" />
            <p className="card-text">{t('custom_plastic_products')}</p>
          </div>
        </div>
      </div>
      <div className="video-section">
        <h2 className="section-title">{t('learn_more')}</h2>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/pasfJjKSMIY"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="EPP Process Video"
          ></iframe>
        </div>
      </div>
      <div className="group-section">
  <h2 className="section-title">{t('our_group')}</h2>
  <div className="group-logos">
    <a href="https://cubikservice.com/en/cubik-service-industrial-cubicature/" target="_blank" rel="noopener noreferrer">
      <img src={`${process.env.PUBLIC_URL}/group-logo1.png`} alt="Group Logo 1" className="group-logo" />
    </a>
    <a href="https://www.politop.com/" target="_blank" rel="noopener noreferrer">
      <img src={`${process.env.PUBLIC_URL}/group-logo2.png`} alt="Group Logo 2" className="group-logo" />
    </a>
    <a href="https://www.dnb.com/business-directory/company-profiles.europlastica_group_srl.8eac21714324211bfee9b30b4ecb3ba9.html" target="_blank" rel="noopener noreferrer">
      <img src={`${process.env.PUBLIC_URL}/group-logo3.png`} alt="Group Logo 3" className="group-logo" />
    </a>
    <a href="https://quaser.eu/en/quaser-qualita-e-servizio/" target="_blank" rel="noopener noreferrer">
      <img src={`${process.env.PUBLIC_URL}/group-logo4.png`} alt="Group Logo 4" className="group-logo" />
    </a>
    <a href="https://www.arcapol.com/" target="_blank" rel="noopener noreferrer">
      <img src={`${process.env.PUBLIC_URL}/group-logo5.png`} alt="Group Logo 5" className="group-logo" />
    </a>
    <a href="http://localhost:3000/en/about_us" target="_blank" rel="noopener noreferrer">
      <img src={`${process.env.PUBLIC_URL}/group-logo6.png`} alt="Group Logo 6" className="group-logo" />
    </a>
  </div>
</div>

      <div className="partners-section">
        <h2 className="section-title">{t('epp_partners')}</h2>
        <div className="marquee">
          <div className="marquee-content">
            <img src={`${process.env.PUBLIC_URL}/partner-logo1.png`} alt="Partner Logo 1" className="partner-logo" />
            <img src={`${process.env.PUBLIC_URL}/partner-logo2.png`} alt="Partner Logo 2" className="partner-logo" />
            <img src={`${process.env.PUBLIC_URL}/partner-logo3.png`} alt="Partner Logo 3" className="partner-logo" />
            <img src={`${process.env.PUBLIC_URL}/partner-logo4.png`} alt="Partner Logo 4" className="partner-logo" />
            <img src={`${process.env.PUBLIC_URL}/partner-logo5.png`} alt="Partner Logo 5" className="partner-logo" />
            <img src={`${process.env.PUBLIC_URL}/partner-logo6.png`} alt="Partner Logo 6" className="partner-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
