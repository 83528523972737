import React from 'react';
import './Glance.css';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import bannerImage from '../../assets/glance.webp';

const Glance = () => {
  const { t } = useTranslation();

  const images = [
    {
        original: `${process.env.PUBLIC_URL}/company1.png`,
        thumbnail: `${process.env.PUBLIC_URL}/company1.png`,
      },
      {
        original: `${process.env.PUBLIC_URL}/company2.png`,
        thumbnail: `${process.env.PUBLIC_URL}/company2.png`,
      },
      {
        original: `${process.env.PUBLIC_URL}/company3.png`,
        thumbnail: `${process.env.PUBLIC_URL}/company3.png`,
      },
      {
        original: `${process.env.PUBLIC_URL}/company4.png`,
        thumbnail: `${process.env.PUBLIC_URL}/company4.png`,
      },
  ];

  return (
    <div className="glance-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('glance_title')}</h1>
      </div>
      <div className="glance-content">
        <h2>{t('glance_intro')}</h2>
        <p>{t('glance_description')}</p>
        <h3>{t('glance_numbers_title')}</h3>
        <ul>
          <li>{t('glance_employees')}</li>
          <li>{t('glance_hours')}</li>
          <li>{t('glance_machines')}</li>
          <li>{t('glance_pad_printing')}</li>
          <li>{t('glance_silk_printing')}</li>
          <li>{t('glance_hot_stamping')}</li>
          <li>{t('glance_ultrasonic_welding')}</li>
          <li>{t('glance_table_top')}</li>
          <li>{t('glance_sub_assembling')}</li>
          <li>{t('glance_quality_team')}</li>
        </ul>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-container">
          <ImageGallery items={images} autoPlay showThumbnails={false} />
        </div>
      </div>
    </div>
  );
};

export default Glance;
