import React from 'react';
import './HotStamping.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/hot.jpg'; // Assuming this is the banner image path

const hotStampingPhotos = [
  'hot1.png',
  'hot2.png',
  'hot3.png',
];

const HotStamping = () => {
  const { t } = useTranslation();

  return (
    <div className="hot-stamping-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('hot_stamping')}</h1>
      </div>
      <div className="hot-stamping-content">
        <h1>{t('hot_stamping')}</h1>
        <p>{t('hot_stamping_description')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {hotStampingPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Hot Stamping ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotStamping;
