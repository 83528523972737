import React from 'react';
import './UltrasonicWelding.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/ultra.jpg'; // Assuming this is the banner image path

const ultrasonicWeldingPhotos = [
  'ultra1.png',
  'ultra2.png',
  'ultra3.png',
  'ultra4.png',
  'ultra5.png',
];

const UltrasonicWelding = () => {
  const { t } = useTranslation();

  return (
    <div className="ultrasonic-welding-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('ultrasonic_welding')}</h1>
      </div>
      <div className="ultrasonic-welding-content">
        <h1>{t('ultrasonic_welding')}</h1>
        <p>{t('ultrasonic_welding_description')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {ultrasonicWeldingPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Ultrasonic Welding ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UltrasonicWelding;
