import React from 'react';
import './Med.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/ecobar2.png'; // Adjust this path as needed

const medicalPhotos = [
  'medical1.png',
  'medical2.png',
];

const MedicalDispenser = () => {
  const { t } = useTranslation();

  return (
    <div className="medical-dispenser-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('medical_dispenser')}</h1>
      </div>
      <div className="medical-dispenser-content">
        <h1>{t('medical_dispenser')}</h1>
        <p>{t('medical_description')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
        <div className="gallery">
          {medicalPhotos.map((photo, index) => (
            <img
              key={index}
              src={`${process.env.PUBLIC_URL}/${photo}`}
              alt={`Medical Dispenser ${index + 1}`}
            />
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalDispenser;
