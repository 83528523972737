import React from 'react';
import './Markets.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/market.jpg'; // Assuming this is the banner image path

const Markets = () => {
  const { t } = useTranslation();

  return (
    <div className="markets-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('markets')}</h1>
      </div>
      <div className="markets-content">
        <p>{t('markets_intro')}</p>
        <ul>
          <li>{t('automotive')}</li>
          <li>{t('textile')}</li>
          <li>{t('white_goods')}</li>
          <li>{t('medical_dispenser')}</li>
        </ul>
        <p>{t('automotive_detail')}</p>
        <p>{t('textile_detail')}</p>
        <p>{t('white_goods_detail')}</p>
        <h3>{t('typical_components')}</h3>
        <p>{t('typical_components_description')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            <img src={`${process.env.PUBLIC_URL}/mark1.png`} alt="Market 1" />
            <img src={`${process.env.PUBLIC_URL}/mark2.png`} alt="Market 2" />
            <img src={`${process.env.PUBLIC_URL}/mark3.png`} alt="Market 3" />
            <img src={`${process.env.PUBLIC_URL}/mark4.png`} alt="Market 4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Markets;
