import React from 'react';
import './Team.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/kontakt.jpg';

const teamMembers = [
  {
    name: 'Dario Pantonin',
    position: 'managing_director',
    image: 'team1.png'
  },
  {
    name: 'Marcin Łukasik',
    position: 'plant_manager',
    image: 'team2.png'
  },
  {
    name: 'Karolina Łaszcz',
    position: 'logistic_manager',
    image: 'team5.png'
  },
  {
    name: 'Monika Warwasiewicz',
    position: 'purchasing_manager',
    image: 'team6.png'
  },
  {
    name: 'Barbara Procek',
    position: 'quality_manager',
    image: 'team7.png'
  },
  {
    name: 'Krystyna Nowicka-Dukiel',
    position: 'chief_accountant',
    image: 'team4.png'
  },
  {
    name: 'Kamila Chojnacka',
    position: 'tampo_manager',
    image: 'team.png'
  },
  {
    name: 'Kamil Gorczynski',
    position: 'project_leader',
    image: 'team.png'
  },
  {
    name: 'Contact Sekretariat',
    position: 'project_business_development',
    image: 'team.png'
  },
];

const Team = () => {
  const { t } = useTranslation();

  return (
    <div className="team-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('team_title')}</h1>
      </div>
      <div className="team-content">
        <div className="team-pyramid">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-member">
              <img src={`${process.env.PUBLIC_URL}/${member.image}`} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{t(member.position)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
