import React from 'react';
import './technologies.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/technologie.jpg'; // Assuming this is the banner image path

const Technologies = () => {
  const { t } = useTranslation();

  return (
    <div className="technologies-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('technologies')}</h1>
      </div>
      <div className="technologies-content">
        <p><strong>{t('hard_work')}</strong> {t('and_dedication')} {t('along_with_the_choice')} <strong>{t('right_technology')}</strong> {t('are_the_recipe')}
          {t('for_manufacturing')} <strong>{t('quality')}</strong> {t('plastic_items')}. {t('from_injection')} {t('to_overmolding')}, {t('passing_through')}
          {t('padprinting')} {t('and_ultrasonic')}, EPP <strong>{t('has_earned')}</strong> {t('the_expertise')} {t('in_the_use')} {t('of_a_large')} {t('variety_of_technologies')}.
        </p>
        <h3>{t('gallery')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            <img src={`${process.env.PUBLIC_URL}/tech1.png`} alt="Technology 1" />
            <img src={`${process.env.PUBLIC_URL}/tech2.png`} alt="Technology 2" />
            <img src={`${process.env.PUBLIC_URL}/tech3.png`} alt="Technology 3" />
            <img src={`${process.env.PUBLIC_URL}/tech4.png`} alt="Technology 4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
