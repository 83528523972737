import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Navbar.css';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const currentLang = i18n.language;

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to={`/${currentLang}/`}><img src={`${process.env.PUBLIC_URL}/logo.webp`} alt="Logo" /></Link>
      </div>
      <div className={`navbar-links ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li role="menuitem" className="nav-item firstmain">
            <Link className="nav-link withsubmenu" to={`/${currentLang}/about_us`}>{t('about_us')}</Link>
            <ul role="menu">
              <li role="menuitem" className="nav-item firstitem"><Link className="nav-link" to={`/${currentLang}/company`}>{t('company')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/group`}>{t('group')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/glance`}>{t('glance')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/team`}>{t('team')}</Link></li>
              <li role="menuitem" className="nav-item lastitem"><Link className="nav-link" to={`/${currentLang}/events`}>{t('events')}</Link></li>
            </ul>
          </li>
          <li role="menuitem" className="nav-item">
            <Link className="nav-link withsubmenu" to={`/${currentLang}/production`}>{t('production')}</Link>
            <ul role="menu">
              <li role="menuitem" className="nav-item firstitem"><Link className="nav-link" to={`/${currentLang}/assembling`}>{t('assembling')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/decorating`}>{t('decorating')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/molding`}>{t('molding')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/maintenance`}>{t('maintenance')}</Link></li>
              <li role="menuitem" className="nav-item lastitem"><Link className="nav-link" to={`/${currentLang}/warehouse`}>{t('warehouse')}</Link></li>
            </ul>
          </li>
          <li role="menuitem" className="nav-item">
            <Link className="nav-link withsubmenu" to={`/${currentLang}/technologies`}>{t('technologies')}</Link>
            <ul role="menu">
              <li role="menuitem" className="nav-item firstitem"><Link className="nav-link" to={`/${currentLang}/injection`}>{t('injection')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/insert_molding`}>{t('insert_molding')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/gas_assist_molding`}>{t('gas_assist_molding')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/overmolding`}>{t('overmolding')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/materials`}>{t('materials')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/glued_table_top`}>{t('glued_table_top')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/pad_printing_silk_printing`}>{t('pad_printing_silk_printing')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/ultrasonic_welding`}>{t('ultrasonic_welding')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/hot_stamping`}>{t('hot_stamping')}</Link></li>
              <li role="menuitem" className="nav-item lastitem"><Link className="nav-link" to={`/${currentLang}/integrated_operation`}>{t('integrated_operation')}</Link></li>
            </ul>
          </li>
          <li role="menuitem" className="nav-item">
            <Link className="nav-link withsubmenu" to={`/${currentLang}/markets`}>{t('markets')}</Link>
            <ul role="menu">
              <li role="menuitem" className="nav-item firstitem"><Link className="nav-link" to={`/${currentLang}/automotive`}>{t('automotive')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/textile`}>{t('textile')}</Link></li>
              <li role="menuitem" className="nav-item"><Link className="nav-link" to={`/${currentLang}/medical`}>{t('medical_dispenser')}</Link></li>
              <li role="menuitem" className="nav-item lastitem"><Link className="nav-link" to={`/${currentLang}/white_goods`}>{t('white_goods')}</Link></li>
            </ul>
          </li>
          <li role="menuitem" className="nav-item"><Link className="nav-link withsubmenu" to={`/${currentLang}/quality`}>{t('quality')}</Link></li>
          <li role="menuitem" className="nav-item"><Link className="nav-link withsubmenu" to={`/${currentLang}/contact`}>{t('contact')}</Link></li>
        </ul>
      </div>
      <div className="navbar-lang">
        <button onClick={() => changeLanguage('en')} className={i18n.language === 'en' ? 'selected' : ''}>EN</button>
        <button onClick={() => changeLanguage('pl')} className={i18n.language === 'pl' ? 'selected' : ''}>PL</button>
        <button onClick={() => changeLanguage('it')} className={i18n.language === 'it' ? 'selected' : ''}>IT</button>
      </div>
      <div className="navbar-burger" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
};

export default Navbar;
