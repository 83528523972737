import React from 'react';
import './Decorating.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/decorating.png'; // Assuming this is the banner image path

const decoratingPhotos = [
  'decorating1.png',
  'decorating2.png',
  'decorating3.png',
  'decorating4.png',
  'decorating5.png',
  'decorating6.png',
  'decorating7.png',
];

const Decorating = () => {
  const { t } = useTranslation();

  return (
    <div className="decorating-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('finishing_decorating')}</h1>
      </div>
      <div className="decorating-content">
        <h1>{t('finishing_decorating')}</h1>
        <p>{t('finishing_decorating_intro')}</p>
        <h2>{t('pad_printing')}</h2>
        <p>{t('pad_printing_intro')}</p>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/bFFc5ZDEkKA"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Pad Printing Video"
          ></iframe>
        </div>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {decoratingPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Decorating ${index + 1}`}
                onError={() => console.error(`Image not found: ${photo}`)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Decorating;
