import React from 'react';
import './GasAssistMolding.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/wtrysk-gazem.jpg'; // Assuming this is the banner image path

const gasAssistMoldingPhotos = [
  's1.png',
  's2.png',
  's3.png',
];

const GasAssistMolding = () => {
  const { t } = useTranslation();

  return (
    <div className="gas-assist-molding-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('gas_assist_molding')}</h1>
      </div>
      <div className="gas-assist-molding-content">
        <h1>{t('gas_assist_molding')}</h1>
        <p>{t('gas_assist_molding_intro')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {gasAssistMoldingPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Gas Assist Molding ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GasAssistMolding;
