import React from 'react';
import { useTranslation } from 'react-i18next';
import './Group.css';
import bannerImage from '../../assets/epp.png';
import cubikLogo from '../../assets/group-logo1.png';
import politopLogo from '../../assets/group-logo2.png';
import quaserLogo from '../../assets/group-logo4.png';
import europlasticaLogo from '../../assets/group-logo3.png';
import logo2 from '../../assets/group-logo6.png';
import arcapolLogo from '../../assets/group-logo5.png';

const groupMembers = [
  {
    name: 'Cubik Service',
    link: 'http://www.cubikservice.com/',
    image: cubikLogo,
    width: 314,
    height: 155
  },
  {
    name: 'Politop',
    link: 'http://www.politop.com/',
    image: politopLogo,
    width: 314,
    height: 99
  },
  {
    name: 'Quaser',
    link: 'https://quaser.eu/en/quaser-qualita-e-servizio/',
    image: quaserLogo,
    width: 314,
    height: 154
  },
  {
    name: 'Europlastica',
    link: '',
    image: europlasticaLogo,
    width: 314,
    height: 154
  },
  {
    name: 'Logo 2',
    link: './onas.html',
    image: logo2,
    width: 314,
    height: 271
  },
  {
    name: 'Arcapol',
    link: 'http://www.arcapol.com/',
    image: arcapolLogo,
    width: 314,
    height: 196
  },
];

const Group = () => {
  const { t } = useTranslation();

  return (
    <div className="group-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('group')}</h1>
      </div>
      <div className="photo-gallery">
        <div className="thumbnails">
          {groupMembers.map((member, index) => (
            <div key={index} className="thumbnail">
              <a href={member.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={member.image}
                  alt={member.name}
                  width={member.width}
                  height={member.height}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Group;
