import React from 'react';
import './Contact.css';
import { FaPhone, FaEnvelope, FaFax } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/kontakt.jpg';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-page">

      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('contact')}</h1>
      </div>
      <div className="contact-content">
        <h2>{t('contact')}</h2>
        <p>{t('contact_intro')}</p>
        <div className="contact-details">
          <h3>{t('plant_office')}</h3>
          <p>{t('address').split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}<br />
            </React.Fragment>
          ))}</p>
          <p><FaPhone /> {t('phone')}: +48 71 381 73 50<br />
          <FaFax /> {t('fax')}: +48 71 381 73 51<br />
          <FaEnvelope /> {t('email')}: sekretariat@epp-pl.com</p>

          <h3>{t('secretariat_office')}</h3>
          <p><FaPhone /> +48 71 381 73 50</p>

          <h3>{t('sales')}</h3>
          <p><FaEnvelope /> sekretariat@epp-pl.com</p>

          <h3>{t('purchasing')}</h3>
          <p><FaPhone /> +48 71 381 73 60</p>

          <h3>{t('logistic')}</h3>
          <p><FaPhone /> +48 71 381 73 62</p>

          <h3>{t('quality')}</h3>
          <p><FaPhone /> +48 71 381 73 70</p>
        </div>
        <h3>{t('reach_us')}</h3>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2540.9639267930336!2d17.37464831574137!3d51.02453947956311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc9cf0415eaf7%3A0x26fda19f8bc84a9!2sPolna%2022%2C%2055-220%20Jelcz-Laskowice%2C%20Poland!5e0!3m2!1sen!2sus!4v1635533273512!5m2!1sen!2sus"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="EPP Location"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
