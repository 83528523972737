import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <footer className="footer">
      <div className="footer-links">
        <ul>
          <li><Link to={`/${currentLang}/about_us`}>{t('about_us')}</Link></li>
          <li><Link to={`/${currentLang}/production`}>{t('production')}</Link></li>
          <li><Link to={`/${currentLang}/technologies`}>{t('technologies')}</Link></li>
          <li><Link to={`/${currentLang}/markets`}>{t('markets')}</Link></li>
          <li><Link to={`/${currentLang}/quality`}>{t('quality')}</Link></li>
          <li><Link to={`/${currentLang}/contact`}>{t('contact')}</Link></li>
        </ul>
      </div>
      <div className="footer-copyright">
        &copy; {new Date().getFullYear()} EPP Sp. z o. o. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
