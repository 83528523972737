import React from 'react';
import './Production.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/production.webp';

const Production = () => {
  const { t } = useTranslation();

  return (
    <div className="production-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('production')}</h1>
      </div>
      <div className="production-content">
        <p>{t('production_intro')}</p>
        <h2>{t('plastic_injection_molding')}</h2>
        <h3>{t('product_capabilities')}</h3>
        <ul>
          <li>{t('small_parts')}</li>
          <li>{t('large_components')}</li>
          <li>{t('multi_component')}</li>
          <li>{t('multi_shot_components')}</li>
          <li>{t('thin_wall')}</li>
        </ul>
        <h3>{t('secondary_operations')}</h3>
        <ul>
          <li>{t('ultrasonic_welding')}</li>
          <li>{t('hot_welding')}</li>
          <li>{t('bonding')}</li>
          <li>{t('hot_stamping')}</li>
          <li>{t('pad_printing')}</li>
          <li>{t('silk_screening')}</li>
          <li>{t('packaging')}</li>
        </ul>
        <h3>{t('molding_process_type')}</h3>
        <ul>
          <li>{t('injection')}</li>
          <li>{t('twin_injection')}</li>
          <li>{t('overmolding')}</li>
          <li>{t('insert_molding')}</li>
          <li>{t('gas_assist')}</li>
        </ul>
        <div className="gallery-section">
          <h3>{t('gallery')}</h3>
          <div className="gallery">
            <img src={`${process.env.PUBLIC_URL}/production1.png`} alt="Production 1" />
            <img src={`${process.env.PUBLIC_URL}/production2.png`} alt="Production 2" />
            <img src={`${process.env.PUBLIC_URL}/production3.png`} alt="Production 3" />
            <img src={`${process.env.PUBLIC_URL}/production4.png`} alt="Production 4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;
