import React from 'react';
import './InsertMolding.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/technologie.jpg'; // Assuming this is the banner image path

const insertMoldingPhotos = [
  'odl1.png',
  'odl2.png',
  'odl3.png',
  'odl4.png',
  'odl5.png',
  'odl6.png',
  'odl7.png',
  'odl8.png',
  'odl9.png',
];

const InsertMolding = () => {
  const { t } = useTranslation();

  return (
    <div className="insert-molding-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('insert_molding')}</h1>
      </div>
      <div className="insert-molding-content">
        <h1>{t('insert_molding')}</h1>
        <p>{t('insert_molding_intro')}</p>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/gEHhHhlR5OQ"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Insert Molding Video"
          ></iframe>
        </div>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {insertMoldingPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Insert Molding ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsertMolding;
