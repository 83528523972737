import React from 'react';
import './Overmolding.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/over.jpg'; // Assuming this is the banner image path

const overmoldingPhotos = [
  'nad1.png',
  'nad2.png',
  'nad3.png',
];

const Overmolding = () => {
  const { t } = useTranslation();

  return (
    <div className="overmolding-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('overmolding')}</h1>
      </div>
      <div className="overmolding-content">
        <h1>{t('overmolding')}</h1>
        <p>{t('overmolding_intro')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {overmoldingPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Overmolding ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overmolding;
