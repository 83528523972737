import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import AboutUs from './components/aboutus/AboutUs';
import Contact from './components/contact/Contact';
import Quality from './components/quality/Quality';
import './App.css';
import Company from './components/company/Company';
import Glance from './components/glance/Glance';
import Team from './components/team/Team';
import Production from './components/production/Production';
import Technologies from './components/tec/technologies.js';
import Markets from './components/markets/markets.js';
import Group from './components/group/Group.js';
import Assembling from './components/assembling/Assembling.js';
import Decorating from './components/decorating/Decorating.js';
import Moulding from './components/molding/Molding.js';
import Maintenance from './components/maintenance/Maintenance.js';
import Warehouse from './components/warehouse/Warehouse.js';
import Injection from './components/injection/Injection.js';
import InsertMolding from './components/insertmolding/InsertMolding.js';
import GasAssistMolding from './components/gasassistmolding/GasAssistMolding.js';
import Overmolding from './components/overmolding/Overmolding.js';
import Materials from './components/materials/Materials.js';
import GluedTableTop from './components/glue/GluedTableTop.js';
import PadSilkPrinting from './components/pad/PadSilkPrinting.js';
import UltrasonicWelding from './components/ultra/UltrasonicWelding.js';
import HotStamping from './components/hot/HotStamping.js';
import IntegratedOperation from './components/operations/IntegratedOperation.js';
import Automotive from './components/automotive/Automotive.js';
import Textile from './components/text/Textile.js';
import MedicalDispenser from './components/med/Med.js';
import WhiteGoods from './components/white/White.js';


const Wrapper = ({ Component }) => {
  const { lang } = useParams();
  return <Component lang={lang} />;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <main>
          <Routes>
            <Route path="/:lang" element={<Wrapper Component={AboutUs} />} />
            <Route path="/:lang/about_us" element={<Wrapper Component={AboutUs} />} />
            <Route path="/:lang/group" element={<Wrapper Component={Group} />} />
            <Route path="/:lang/contact" element={<Wrapper Component={Contact} />} />
            <Route path="/:lang/quality" element={<Wrapper Component={Quality} />} />
            <Route path="/:lang/company" element={<Wrapper Component={Company} />} />
            <Route path="/:lang/glance" element={<Wrapper Component={Glance} />} />
            <Route path="/:lang/team" element={<Wrapper Component={Team} />} />
            <Route path="/:lang/production" element={<Wrapper Component={Production} />} />
            <Route path="/:lang/assembling" element={<Wrapper Component={Assembling} />} />
            <Route path="/:lang/decorating" element={<Wrapper Component={Decorating} />} />
            <Route path="/:lang/molding" element={<Wrapper Component={Moulding} />} />
            <Route path="/:lang/technologies" element={<Wrapper Component={Technologies} />} />
            <Route path="/:lang/injection" element={<Wrapper Component={Injection} />} />
            <Route path="/:lang/insert_molding" element={<Wrapper Component={InsertMolding} />} />
            <Route path="/:lang/gas_assist_molding" element={<Wrapper Component={GasAssistMolding} />} />
            <Route path="/:lang/overmolding" element={<Wrapper Component={Overmolding} />} />
            <Route path="/:lang/materials" element={<Wrapper Component={Materials} />} />
            <Route path="/:lang/glued_table_top" element={<Wrapper Component={GluedTableTop} />} />
            <Route path="/:lang/pad_printing_silk_printing" element={<Wrapper Component={PadSilkPrinting} />} />
            <Route path="/:lang/ultrasonic_welding" element={<Wrapper Component={UltrasonicWelding} />} />
            <Route path="/:lang/hot_stamping" element={<Wrapper Component={HotStamping} />} />
            <Route path="/:lang/integrated_operation" element={<Wrapper Component={IntegratedOperation} />} />
            <Route path="/:lang/maintenance" element={<Wrapper Component={Maintenance} />} />
            <Route path="/:lang/warehouse" element={<Wrapper Component={Warehouse} />} />
            <Route path="/:lang/markets" element={<Wrapper Component={Markets} />} />
            <Route path="/:lang/medical" element={<Wrapper Component={MedicalDispenser} />} />
            <Route path="/:lang/automotive" element={<Wrapper Component={Automotive} />} />
            <Route path="/:lang/textile" element={<Wrapper Component={Textile} />} />
            <Route path="/:lang/white_goods" element={<Wrapper Component={WhiteGoods} />} />

            {/* Add other routes here */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
