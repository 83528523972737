import React from 'react';
import './PadSilkPrinting.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/tampo.jpg'; // Assuming this is the banner image path

const padSilkPrintingPhotos = [
  'pad1.png',
  'pad2.png',
  'pad3.png',
  'pad4.png',
  'pad5.png',
  'pad6.png',
  'pad7.png',
  'pad8.png',
  'pad9.png',
  'pad10.png',
];

const PadSilkPrinting = () => {
  const { t } = useTranslation();

  return (
    <div className="pad-silk-printing-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('pad_silk_printing')}</h1>
      </div>
      <div className="pad-silk-printing-content">
        <h1>{t('pad_silk_printing')}</h1>
        <p>{t('pad_printing_description')}</p>
        <p>{t('silk_printing_description')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {padSilkPrintingPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Pad Silk Printing ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PadSilkPrinting;
