import React from 'react';
import './Injection.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/injection.jpg'; // Assuming this is the banner image path

const injectionPhotos = [
  'wt1.png',
  'wt2.png',
  'wt3.png',
  'wt4.png',
  'wt5.png',
  'wt6.png',
  'wt7.png',
  'wt8.png',
  'wt9.png',
];

const Injection = () => {
  const { t } = useTranslation();

  return (
    <div className="injection-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('injection')}</h1>
      </div>
      <div className="injection-content">
        <h1>{t('injection')}</h1>
        <p>{t('injection_intro')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {injectionPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Injection ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Injection;
