import React from 'react';
import './IntegratedOperation.css';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/int.jpg'; // Assuming this is the banner image path

const integratedOperationPhotos = [
  'int1.png',
  'int2.png',
  'int3.png',
  'int4.png',
];

const IntegratedOperation = () => {
  const { t } = useTranslation();

  return (
    <div className="integrated-operation-page">
      <div className="banner-section" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1>{t('integrated_operation')}</h1>
      </div>
      <div className="integrated-operation-content">
        <h1>{t('integrated_operation')}</h1>
        <p>{t('integrated_operation_description')}</p>
        <h3>{t('gallery_title')}</h3>
        <div className="gallery-section">
          <div className="gallery">
            {integratedOperationPhotos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/${photo}`}
                alt={`Integrated Operation ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegratedOperation;
